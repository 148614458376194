// ドロワーメニュー開く
window.addEventListener('load', () => {
  const body = document.body;
  const triggers = document.querySelectorAll('.js-drawerOpen');
  if (!triggers) return;

  triggers.forEach((trigger) => {
    trigger.addEventListener('click', () => {
      body.dataset.menuOpen = body.dataset.menuOpen === 'true' ? 'false' : 'true';
    });
  });
});
