// スクロールするとヘッダーの一部を固定
window.addEventListener('load', () => {
  document.querySelectorAll('.js-isReached').forEach((object) => {
    // 対象オブジェクトのオフセット量
    let offset = window.scrollY + object.getBoundingClientRect().top;
    // 基準ポシション
    const position = object.getAttribute('data-position');
    // 発火場所
    if (position === 'bottom') offset = offset - window.parent.screen.height;

    window.addEventListener('scroll', () => {
      if (new URLSearchParams(window.location.search).get('b')) {
        object.setAttribute('data-isReached', (window.scrollY + 1000 > offset ? 1 : 0).toString());
      } else {
        object.setAttribute('data-isReached', (window.scrollY > offset ? 1 : 0).toString());
      }
    });
  });
});
